/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'arrow-b': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.668 9.507A.48.48 0 008 9.636a.459.459 0 00.329-.129c1.025-.976 1.912-1.945 2.888-2.942a.202.202 0 00-.143-.343H4.918a.2.2 0 00-.144.34c.969 1.004 1.87 1.97 2.894 2.945z" _fill="#fff"/>'
  }
})
